<template>
  <v-container class="ma-0 pa-0">
    <v-card>
      <v-toolbar
        dark
        color="blue-grey"
        height="60"
      >
        <v-col cols="2">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fecha1"
                label="Fecha 1"
                outlined
                dense
                class="mt-7"
                prepend-inner-icon="mdi-calendar"
                                     
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="fecha1"
              locale="es"
              @input="menu1 = false"
              @change="consultar"
            />
          </v-menu>
        </v-col>

        <v-col cols="2">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fecha2"
                outlined
                dense
                class="mt-7"
                label="Fecha 2"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="fecha2"
              locale="es"
              @input="menu2 = false"
              @change="consultar"
            />
          </v-menu>
        </v-col>

        <v-col cols="3">
          <leecontacto />
        </v-col>
        <v-col cols="3">
          <leecliente class="mt-5" />
        </v-col>

        <v-btn
          color="success"
          @click="consultar"
        >
          Consultar
          <v-icon class="ml-1">
            mdi-update
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

    <!-- GRAFICAS -->
    <v-row>
      <v-col
        cols="12"
        sm="12"
        lg="3"
        xl="3"
      >
        <v-card
          class="mt-2"
          elevation="10"
        >
          <ordxmes />
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        lg="3"
        xl="3"
      >
        <v-card
          class="mt-2"
          elevation="10"
        >
          <graficaact />
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        lg="3"
        xl="3"
      >
        <v-card
          class="mt-2"
          elevation="10"
        >
          <graficacitas />
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        lg="3"
        xl="3"
      >
        <v-card
          class="mt-2"
          elevation="10"
        >
          <graficaopo />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import graficaact from '@/views/graficas/graficaact.vue'
import graficacitas from '@/views/graficas/graficacitas.vue'
import ordxmes from '@/views/graficas/ordxmes'
import graficaopo from '@/views/graficas/graficaopo'
import leecontacto from '@/components/leecontacto'
import leecliente from '@/components/leecliente'
import { mapActions, mapGetters} from 'vuex'


export default {
  components:{
    graficaact,
    graficacitas,
    ordxmes,
    graficaopo,
    leecontacto,
    leecliente
  },
  data: () => ({
    fecha1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    fecha2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
    idcontacto: '',
    idweb: ''
  }),

  computed: {
      
  },
  
  created(){
    let date0 = new Date()
    let mes = date0.getMonth()
    let anio = date0.getFullYear()
    // console.log(mes, anio)
    this.fecha1 = new Date(anio, mes, 1).toISOString().substr(0, 10)

    this.consultar()

    // crear

  },


  // mounted() {
  //  this.consultar()
  // },
  
  methods: {
    ...mapActions('controles',['setControles']),
    // ...mapActions("snackbar", ["showSnack"]),

    consultar(){
      this.grafica= []
      this.labes=[]
      this.value=[]
   
      let controles= {
        fecha1 : this.fecha1,
        fecha2 : this.fecha2,
        idcontacto: this.idcontacto,
        idweb : this.idweb
      }
      this.loading = true

      // console.log(controles)
      this.setControles(controles)

    },
  
   
  },
}
</script>

<style>
</style>